<template>
  <b-nav-item @click="onclickTheme">
    <feather-icon
      size="21"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api' // , onUnmounted
import { BNavItem } from 'bootstrap-vue'
// import memberStoreModule from '@/views/profile/components/useStoreModule'
import store from '@/store'

export default {
  components: {
    BNavItem,
  },
  methods: {
    onclickTheme() {
      this.skin = this.isDark ? 'semi-dark' : 'dark'
      window.user.setStyle(this.isDark ? 1 : 0)
      // this.$socket.emit('setStyle', this.isDark ? 1 : 0)
      //
      // this.setUserConfig({ useDark: this.isDark ? 1 : 0 })
    },
  },
  setup() {
    // 模組註冊
    // const MEMBER_CLIENT_STORE_MODULE_NAME = 'client-member'

    // if (!store.hasModule(MEMBER_CLIENT_STORE_MODULE_NAME)) store.registerModule(MEMBER_CLIENT_STORE_MODULE_NAME, memberStoreModule)

    // onUnmounted(() => {
    //   if (store.hasModule(MEMBER_CLIENT_STORE_MODULE_NAME)) store.unregisterModule(MEMBER_CLIENT_STORE_MODULE_NAME)
    // })

    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    const setUserConfig = (...args) => store.dispatch('client-member/setUserConfig', ...args)

    return {
      skin,
      isDark,
      setUserConfig,
    }
  },
}
</script>
