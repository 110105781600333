<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © 2013 - {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        :to="{ name: 'home' }"
      >
        GameHost
      </b-link>
      <!-- href="https://1.envato.market/pixinvent_portfolio"
        target="_blank" -->
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      Powered by GameHost
      <b-img
        :src="require('@/assets/images/logo/icon-sm.png')"
        size="21"
        class="stroke-current"
        alt="logo"
      />
    </span>
  </p>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
  },
}
</script>
